/** @jsx jsx */

import { jsx, Flex } from "theme-ui"
import { graphql } from 'gatsby'


import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import BasicHero from '../components/BasicHero'

import { Container } from '../components/item'

import BlogCard from "../components/BlogCard"


const Blog = ({data}) => {

  const posts = data.posts.edges;
  return (
  <Layout>
    <SEO title="Product Category" />
    
    <BasicHero 
      fluid={data.hero.childImageSharp.fluid}  
      title={"House Image"} 
      heroTitle={"Raptect Blog"}
      shapeColor={'transparent'}
    />

    <Container py={4} sx={{marginTop: '3rem'}}>
      <Flex sx={{
        flexWrap: 'wrap',
        marginLeft: '-24px',
        marginRight: '-24px'
      }}>
        { posts.map( ( { node }, index ) => (
            
            <BlogCard 
                key={index} 
                post={node} 
                alt={ index === 0 ? true : false }
            />
            
        ))}
      </Flex>
    </Container>
  </Layout>
  )
}

export default Blog

// const StyledCardLink = styled(Link)`
//     display: inline-block;
//     font-weight: bold;
//     color: white;
//     text-decoration: none;
//     position: relative;
//     margin-top: 20px;

// `


export const pageQuery = graphql`
    query blogpageQuery($limit: Int!, $skip: Int!) {
        posts: 
          allWordpressPost(
            filter: {categories: {elemMatch: {slug: { eq: "raptect" }}}} 
            limit: $limit,
            skip: $skip
          ){
            edges {
              node {
                slug
                title
                excerpt
                date(formatString: "MMMM DD, YYYY")
                featured_media {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
          hero: file(relativePath: { eq: "Raptect-blog-parent-hero.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
    }
`