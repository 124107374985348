/** @jsx jsx */
import { Styled, Flex, jsx } from 'theme-ui'
import styled from '@emotion/styled'
import BgImage from '../components/BgImage'
import { Container } from '../components/item'

const Parallelogram = styled.div`
  position: absolute;
  top: 0;
  left: -15%;
  width: 100%;
  height: 100%;
  background: ${props => props.shapeColor || 'rgba(0,0,0,0.8)'};
  transform: skew(-20deg);
  border-bottom-right-radius: 100px;

  @media screen and (max-width: 767px) {
    left: -20%;
    width: 115%;
  }

`

const HeroContent = styled(Container)`
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
`

const InnerHero = styled.div`
  color: white;
  max-width: 650px;

  @media screen and (max-width: 1000px) {
    padding-top: 60px;
    max-width: 550px;
  }

  @media screen and (max-width: 768px) {
   
    max-width: 300px;
  }
`

const BasicHero = ({fluid, title, heroTitle, heroText, props, shapeColor}) => (
    <header
      {...props}
      sx={{
        
      }}
    >
      <BgImage
        fluid={fluid}
        title={title}
      >
        <Flex sx={{ position: 'relative', height: ['350px', '650px', '650px']}}>
          <Parallelogram shapeColor={shapeColor} />
          <HeroContent>
              <InnerHero>
                <Styled.h1 sx={{
                  fontSize: ['32px', '40px'],
                  textTransform: 'uppercase',
                  color: 'white',
                }}>{heroTitle}</Styled.h1>
                <Styled.p sx={{color: 'white', fontSize: 2}}>{heroText}</Styled.p>
              </InnerHero> 
            </HeroContent>
        </Flex>
      </BgImage>
      
    </header>
  
)

export default BasicHero

//TODO: Add sx property to allow for correct colors